/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Player as ShakaPlayer, extern, net, polyfill, util } from '@shakaPlayer';
import { ILogger } from 'js-logger';
import { includes, last } from 'ramda';
import { ExternalPlayerRecoveryErrorEvent } from '../..';
import { AdBreaksParsingType } from '../../../consts/adBreaks';
import { SECOND } from '../../../consts/date';
import {
    KeySystem,
    KeySystemToProtectionSystemType,
    ProtectionSystemType,
    ProtectionSystemTypeToId,
    drmSecurityLevel
} from '../../../consts/drm';
import { INFINITE_DURATION } from '../../../consts/duration';
import { AdErrorCode } from '../../../consts/error';
import { OS } from '../../../consts/os';
import { StreamingProtocol } from '../../../consts/protocol';
import { PlayerEvent, PlayerEventTarget } from '../../../events/playerEvent';
import PlayerLogger from '../../../logger/logger';
import { PlayerLoggerEventType, PlayerLoggerLevelChagedEvent } from '../../../logger/loggerEvent';
import SegmentsRecovery from '../../../segmentsRecovery';
import {
    GetDrmCertificate,
    GetLicense,
    GetSessionManagerUrl,
    ParseOpportunity,
    ParsePlacement,
    ParseScte,
    PersistentLicenseOptions,
    PlayerAdBreak,
    PlayerScteSegment,
    RestrictionsConfiguration,
    TransformUrl
} from '../../../shared';
import { isLicenseError, isLicenseServerFatalError } from '../../../utils/error/error';
import { ROLE_VALUES } from '../../../utils/manifest';
import { fromUtf8 } from '../../../utils/string';
import ExternalPlayer, {
    DrmConfiguration,
    ExternalPlayerBufferedInfo,
    ExternalPlayerStatistics,
    ExternalPlayerTrack
} from '../../externalPlayer';
import {
    ExternalLicenseRenewalNeeded,
    ExternalPlayerAdErrorEvent,
    ExternalPlayerBufferingEvent,
    ExternalPlayerErrorEvent,
    ExternalPlayerEventType,
    ExternalPlayerLoaded,
    ExternalPlayerManifestParsedEvent,
    ExternalPlayerManifestTypeChangedEvent,
    ExternalPlayerTextChangedEvent, ExternalPlayerTrackChangedEvent, ExternalPlayerVariantChangedEvent
} from '../../externalPlayerEvent';
import UITextDisplayer from './customTextDisplayer';
import LicenseRenewalManager from './licenseRenewal/licenseRenewalManager';
import { LicenseRenewal } from './licenseRenewal/types';
import ManifestTracks from './models/manifestTracks';
import PersistentLicenseStorage from './persistentLicense/persistentLicenseStorage';
import { LicenseStorage } from './persistentLicense/types';
import { ShakaPlayerEventType } from './types/ShakaPlayerEventType';

export interface ShakaExternalPlayerOptions {
    readonly shakaPlayer: ShakaPlayer;
    readonly customLicenseProtocol: string;
    readonly getDrmCertificate: GetDrmCertificate;
    readonly getLicense: GetLicense;
    readonly transformUrl: TransformUrl;
    readonly parseOpportunity: ParseOpportunity;
    readonly parseScte: ParseScte;
    readonly parsePlacement: ParsePlacement;
    readonly playerLogger: PlayerLogger;
    readonly storage: Storage;
    readonly segmentsRecoveryService: SegmentsRecovery;
    readonly keySystemPriority?: KeySystem[];
    readonly isAdPlayer: boolean;
    readonly getSessionManagerUrl: GetSessionManagerUrl;
    readonly videoContainer: HTMLDivElement;
    readonly targetOs: string;
}

type DrmSupportType = {
    persistentState: boolean,
    persistentStateRequired: boolean,
};

type SupportType = Record<ProtectionSystemType, DrmSupportType | null>;

interface ShakaExternalPlayerDrmInfo {
    readonly name: string;
    readonly id: string;
    readonly info: DrmSupportType | null | undefined;
    readonly securityLevel: string;
}

interface DrmSupport {
    [key: string]: DrmSupportType | null;
}

interface ShakaInitDataTransform {
    (initData: Uint8Array, initDataType: string, drmInfo: extern.DrmInfo | null): Uint8Array;
}

interface ShakaNetworkError {
    isStreamingError: boolean;
    httpCode: number;
}

export interface ExternalPlayerResponse {
    data: string;
    headers: Headers;
    status: number;
}

export interface ManifestData {
    masterPlaylist: string;
    mediaPlaylist: string;
}

export interface ManifestConfig {
    minimumUpdatePeriod: number;
}

export const basicVideoCapabilities = [
    { contentType: 'video/mp4; codecs="avc1.42E01E"' },
    { contentType: 'video/webm; codecs="vp8"' },
];

const basicConfig = {
    initDataTypes: ['cenc'],
    videoCapabilities: basicVideoCapabilities,
};

const offlineConfig = {
    videoCapabilities: basicVideoCapabilities,
    persistentState: 'required',
    sessionTypes: ['persistent-license'],
};

const DEFAULT_STALL_SKIP = 0.1;
const DEFAULT_GAP_DETECTION_THRESHOLD = 0.3;

export default class ShakaExternalPlayer extends PlayerEventTarget<ExternalPlayerEventType> implements ExternalPlayer {
    private static selectDrmByPriority(
        priority: ProtectionSystemType[],
        drmSupport: DrmSupport
    ): ShakaExternalPlayerDrmInfo | null {
        const selected = priority.find((type) => drmSupport[type]);

        if (!selected) {
            return null;
        }

        return {
            name: selected,
            id: ProtectionSystemTypeToId[selected],
            info: drmSupport[selected],
            securityLevel: drmSecurityLevel[selected],
        };
    }
    protected readonly initDataTransform: ShakaInitDataTransform | undefined = undefined;
    protected readonly drmPriority: ProtectionSystemType[] = [];
    protected readonly mimeType: string = '';
    protected readonly manifestData: ManifestData | null = null;
    protected readonly shakaPlayer: ShakaPlayer;
    private readonly customLicenseProtocol: string;
    private readonly getDrmCertificate: GetDrmCertificate;
    private readonly getLicense: GetLicense | null = null;
    private readonly transfromUrl: TransformUrl;
    protected readonly parseOpportunity: ParseOpportunity;
    protected readonly parsePlacement: ParsePlacement;
    protected readonly parseScte: ParseScte;
    protected readonly logger: ILogger;
    private readonly licenseStorage: LicenseStorage;
    private licenseRenewalManager: LicenseRenewal | null = null;
    private readonly segmentsRecoveryService: SegmentsRecovery;
    public readonly protocol: StreamingProtocol = StreamingProtocol.None;
    private readonly getSessionManagerUrl: GetSessionManagerUrl;
    public securityLevel: string;
    private readonly videoContainer: HTMLDivElement;
    private readonly targetOs: string;

    private selectedDrm: ShakaExternalPlayerDrmInfo | null = null;
    private persistentLicenseOptions: PersistentLicenseOptions | null = null;
    private unloadPromise: Promise<void> | null = null;
    private url: string;
    private drmContentId: string;
    private manifestResponseHeader: string;
    private startOverUpdateInterval: number;
    private defaultSoftRestrictions: extern.Restrictions;
    private defaultHardRestrictions: extern.Restrictions;
    protected keyId: string | null = null;
    protected audioDescriptionStandartWayOfSignaling: string[] = [];
    protected hardOfHearingStandartWayOfSignaling: string[] = [];
    protected playerAdBreaks: PlayerAdBreak[] = [];
    protected playerScteSegments: PlayerScteSegment[] = [];
    protected adBreakType: AdBreaksParsingType = AdBreaksParsingType.none;
    protected shouldParsePreRoll = false;
    protected audioManifestTracks: ManifestTracks | null = null;
    protected subtitlesManifestTracks: ManifestTracks | null = null;
    protected forceKeySystem: KeySystem | null = null;

    constructor(opt: ShakaExternalPlayerOptions) {
        super();
        polyfill.installAll();
        polyfill.PatchedMediaKeysApple.install();

        this.logger = opt.playerLogger.forName(opt.isAdPlayer ? 'ShakaExternalPlayer.Ads' : 'ShakaExternalPlayer.Main');
        this.shakaPlayer = opt.shakaPlayer;
        this.customLicenseProtocol = opt.customLicenseProtocol;
        this.getDrmCertificate = opt.getDrmCertificate;
        this.getLicense = opt.getLicense;
        this.transfromUrl = opt.transformUrl;
        this.parseOpportunity = opt.parseOpportunity;
        this.parsePlacement = opt.parsePlacement;
        this.parseScte = opt.parseScte;
        this.segmentsRecoveryService = opt.segmentsRecoveryService;
        this.getSessionManagerUrl = opt.getSessionManagerUrl;
        this.videoContainer = opt.videoContainer;
        this.targetOs = opt.targetOs;
        this.url = '';
        this.drmContentId = '';
        this.unloadPromise = null;
        this.securityLevel = '';
        this.startOverUpdateInterval = 0;
        this.manifestResponseHeader = '';

        const shakaConfiguration = this.shakaPlayer.getConfiguration();

        this.defaultSoftRestrictions = shakaConfiguration.abr.restrictions;
        this.defaultHardRestrictions = shakaConfiguration.restrictions;

        this.licenseStorage = new PersistentLicenseStorage({ storage: opt.storage });

        this.configureStreaming();
        this.configureLogLicenseExchange(opt.playerLogger.isEnabled);
        this.registerLoggerEvents(opt.playerLogger);
        this.registerCustomLicensePlugin();
        this.registerShakaPlayerEvents();
        this.registerNetworkFilter();
    }

    cleanPersistentLicense(): void {
        this.licenseStorage.clear();
    }

    protected configureStreaming(): void {
        // this.shakaPlayer.configure('streaming.jumpLargeGaps', true);
        this.shakaPlayer.configure('streaming.stallSkip', DEFAULT_STALL_SKIP);
        this.shakaPlayer.configure('streaming.gapDetectionThreshold', DEFAULT_GAP_DETECTION_THRESHOLD);
    }

    public onLoadedData(): void {
        //empty
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected async preload(_url: string, _manifest: string, _mediaPlaylist?: string): Promise<void> {
        //empty
    }

    protected configureManifestParser(): void {
        //
    }

    private registerNetworkFilter(): void {
        const netEngine = this.shakaPlayer.getNetworkingEngine();

        netEngine?.registerRequestFilter(this.requestFilter);
        netEngine?.registerResponseFilter(this.responseFilter);
    }

    private readonly requestFilter = (
        requestType: net.NetworkingEngine.RequestType,
        request: extern.Request
    ): void => {
        this.logger.trace('requestFilter::', requestType, request);

        switch (requestType) {
            case net.NetworkingEngine.RequestType.MANIFEST:
                this.manifestRequestHandler(request);
                break;
            case net.NetworkingEngine.RequestType.LICENSE:
                // this.licenseRequestHandler(request);
                break;
            case net.NetworkingEngine.RequestType.SEGMENT:
                this.segmentRequestHandler(request);
                break;
        }
    };

    private manifestRequestHandler(request: extern.Request): void {
        request.uris[0] = this.transfromUrl(request.uris[0], this.protocol, this.drmContentId);
    }

    private segmentRequestHandler(request: extern.Request): void {
        request.uris[0] = this.transfromUrl(request.uris[0], this.protocol, this.drmContentId);
    }

    private readonly responseFilter = (
        requestType: net.NetworkingEngine.RequestType,
        response: extern.Response
    ): void => {
        this.logger.trace('responseFilter::', requestType, response);

        switch (requestType) {
            case net.NetworkingEngine.RequestType.MANIFEST:
                this.manifestResponseHandler(response);
                break;
            case net.NetworkingEngine.RequestType.LICENSE:
                // this.licenseResponseHandler(response);
                break;
            case net.NetworkingEngine.RequestType.SEGMENT:
                this.segmentResponseHandler(response);
                break;
        }
    };

    private segmentResponseHandler(response: extern.Response) {
        this.segmentsRecoveryService.processNewSegment(response);
    }

    private registerLoggerEvents(logger: PlayerLogger): void {
        logger.addEventListener(PlayerLoggerEventType.LevelChanged, this.trackLoggerLevel);
    }

    private readonly trackLoggerLevel = (event: PlayerEvent): void => {
        this.configureLogLicenseExchange((event as PlayerLoggerLevelChagedEvent).isEnabled);
    };

    private configureLogLicenseExchange(isEnabled: boolean) {
        this.shakaPlayer.configure('drm.logLicenseExchange', isEnabled);
    }

    private readonly configurePlayerBasedOnSupport = (support: SupportType): void => {
        this.logger.debug('probeSupport::', support);

        this.configureDrmBasedOnSupport(support);
    };

    private configureDrmBasedOnSupport(drmSupport: DrmSupport): void {
        const drmPriority = this.forceKeySystem ? [KeySystemToProtectionSystemType[this.forceKeySystem]] : this.drmPriority;

        this.selectedDrm = ShakaExternalPlayer.selectDrmByPriority(drmPriority, drmSupport);

        this.logger.debug('selected drm is: ', this.selectedDrm);

        if (!this.selectedDrm) {
            this.isBrowserSupported = false;

            return;
        }

        const sessionType = (this.selectedDrm.info?.persistentStateRequired && this.supportsPersistentLicense()) ? 'persistent-license' : 'temporary';

        this.logger.debug('session type: ', sessionType);

        this.securityLevel = this.selectedDrm.securityLevel;

        this.shakaPlayer.configure({
            drm: {
                retryParameters: {
                    maxAttempts: 1,
                },
                servers: {
                    [this.selectedDrm.name]: `${this.customLicenseProtocol}://license.placeholder`,
                },
                advanced: {
                    [this.selectedDrm.name]: {
                        sessionType
                    },
                },
                initDataTransform: this.initDataTransform,
            }
        });
    }

    private supportsPersistentLicense(): boolean {
        const notSupported = ['safari', 'firefox'];

        const browser = this.persistentLicenseOptions?.browser ?? '';

        return !notSupported.includes(browser);
    }

    private disablePersistenceLicense(): void {
        this.shakaPlayer.configure({
            drm: {
                persistentLicenseEnabled: false,
            },
        });
    }

    private configurePersistenceLicense(): void {
        if (this.supportsPersistentLicense()) {
            const storedSessions = this.licenseStorage.getStorage();

            this.shakaPlayer.configure({
                drm: {
                    persistentStateRequired: true,
                    persistentSessionOnlinePlayback: true,
                    persistentSessionsMetadata: storedSessions
                }
            });
        }
    }

    private configureLicenseRenewal(): void {
        if (this.isPersistentLicenseEnabled) {
            return;
        }

        this.licenseRenewalManager = new LicenseRenewalManager({
            renewLicenseBeforeExpiration: this.persistentLicenseOptions?.renewLicenseBeforeExpiration,
        });
    }

    private get isPersistentLicenseEnabled(): boolean {
        return (this.persistentLicenseOptions?.isPersistentLicenseEnabled ?? false)
            && (this.persistentLicenseOptions?.isLive ?? false)
            && (this.selectedDrm?.info?.persistentState ?? false)
            && (this.selectedDrm?.name == ProtectionSystemType.Widevine ?? false);
    }

    private preloadCertificate(): Promise<void> {
        if (this.selectedDrm?.name == ProtectionSystemType.Playready) {
            return Promise.resolve();
        }

        return this.getDrmCertificatePendingOperation(this.selectedDrm?.id ?? 'webunknown')
            .then((cert) => {
                this.logger.log('cert was preloaded:', cert);
                this.shakaPlayer.configure('drm.advanced', {
                    [this.selectedDrm!.name]: {
                        serverCertificate: cert,
                    },
                });
            }).catch((e) => {
                this.logger.error('failed to preload cert: ', e);
            });
    }

    private getDrmCertificatePendingOperation(drmSchemeId: string): Promise<Uint8Array> {
        return new Promise<Uint8Array>((resolve, reject) => {
            this.logger.debug('custom.drmCertificate.plugin::drmSchemeId', drmSchemeId);
            this.getDrmCertificate(drmSchemeId, resolve, reject);
        });
    }

    private getLicensePendingOperation(payload: Uint8Array | null): Promise<Uint8Array> {
        return new Promise<Uint8Array>((resolve, reject) => {
            this.logger.debug('custom.license.plugin::payload.wrapped', payload);
            //selected drm is defenetly exists if license request is called.
            const { id, name } = this.selectedDrm!;

            this.logger.debug('custom.license.plugin::drm', name);

            this.getLicense!(id, payload, this.keyId, this.drmContentId, resolve, reject);
        });
    }

    private registerCustomLicensePlugin(): void {
        if (!this.getLicense) {
            return;
        }

        const pluginScheme = this.customLicenseProtocol;
        const pluginPriority = net.NetworkingEngine.PluginPriority.APPLICATION;
        const plugin = (
            uri: string,
            request: extern.Request
        ): extern.IAbortableOperation<extern.Response> => {
            const raw = request.body as ArrayBuffer;
            const wrapped = new Uint8Array(raw);

            const pendingOperation = this.getLicensePendingOperation(wrapped)
                .then((data) => ({ data, uri, headers: {}, originalUri: uri }));

            const onAbort = () => Promise.resolve();

            return new util.AbortableOperation(pendingOperation, onAbort);
        };

        net.NetworkingEngine.registerScheme(pluginScheme, plugin, pluginPriority);
    }

    private registerShakaPlayerEvents(): void {
        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.Buffering,
            this.onShakaBuffering
        );

        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.TextChanged,
            this.onShakaTextChanged
        );

        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.VariantChanged,
            this.onShakaVariantChanged
        );

        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.Error,
            this.onShakaError
        );

        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.ExpirationUpdated,
            this.onShakaExpirationUpdated
        );

        this.shakaPlayer.addEventListener(
            ShakaPlayerEventType.Adaptation,
            this.onShakaTrackChanged
        );
    }

    private readonly onShakaError = (errorEvent: Event): void => {
        const error = errorEvent.detail as util.Error;

        this.logger.log('Shaka error occured', error);

        if (this.isRecoverableError(error)) {
            return this.onRecoverableError(error);
        }

        if (this.isPersistentLicenseRetrievalError(error)) {
            return this.onPersistentLicenseRetrievalError();
        }

        this.reportPossibleAdError(error);

        this.dispatchErrorEvent(error);
    };

    private reportPossibleAdError(error: util.Error): void {
        if (this.isChunkFailureError(error)) {
            return this.dispatchAdErrorEvent(AdErrorCode.ChunkFailed);
        }

        if (this.isChunkTimeoutError(error)) {
            return this.dispatchAdErrorEvent(AdErrorCode.ChunkTimeout);
        }

        this.dispatchAdErrorEvent(AdErrorCode.PlaybackError);
    }

    private isChunkFailureError(error: util.Error): boolean {
        const errorCode = error.code as number;
        const { Code } = util.Error;

        if (errorCode !== Code.BAD_HTTP_STATUS && errorCode !== Code.HTTP_ERROR) {
            return false;
        }

        return this.isChunkRelatedError(error);
    }

    private isChunkTimeoutError(error: util.Error): boolean {
        const errorCode = error.code as number;
        const { Code } = util.Error;

        if (errorCode !== Code.TIMEOUT) {
            return false;
        }

        return this.isChunkRelatedError(error);
    }

    private isChunkRelatedError(error: util.Error): boolean {
        const errorData = error.data as string[];
        const [url] = errorData;
        const sessionManagerUrl = this.getSessionManagerUrl();

        return !url?.includes(sessionManagerUrl);
    }

    private dispatchAdErrorEvent(code: AdErrorCode) {
        this.dispatchEvent(new ExternalPlayerAdErrorEvent(code, this.url));
    }

    protected shouldRecoverError(): boolean {
        return true;
    }

    private isRecoverableError(error: util.Error): boolean {
        if (!this.shouldRecoverError()) {
            return false;
        }

        const errorCode = error.code as number;
        const { Code } = util.Error;

        return errorCode == Code.VIDEO_ERROR;
    }

    private onRecoverableError(error: util.Error): void {
        this.logger.log('Playback has failed. Trying to recover');

        if (this.segmentsRecoveryService.isRecoverable()) {
            this.segmentsRecoveryService.processError();

            this.dispatchRecoveryErrorEvent();
        } else {
            this.logger.log('Can\'t recover playback');

            this.dispatchErrorEvent(error);
        }
    }

    private isPersistentLicenseRetrievalError(error: util.Error): boolean {
        const persistentLicenseInterface = this.shakaPlayer.getDrmPersistentLicenseInterface();

        if (!persistentLicenseInterface) {
            return false;
        }

        if (isLicenseError(error)) {
            return isLicenseServerFatalError(error);
        }

        const errorCode = error.code as number;
        const { Code } = util.Error;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return includes(errorCode, [
            Code.FAILED_TO_CREATE_SESSION,
            Code.INIT_DATA_TRANSFORM_ERROR,
            Code.FAILED_TO_GENERATE_LICENSE_REQUEST,
            Code.LICENSE_REQUEST_FAILED,
            Code.LICENSE_RESPONSE_REJECTED,
        ]);
    }

    private onPersistentLicenseRetrievalError(): void {
        this.logger.log('Persistent license request was failed. Trying regular license');

        this.disablePersistenceLicense();

        void this._load(this.url);
    }

    // for unknown reason simple reverse mapping from enum values to enum names doesn't work here
    // so here is the only way to get error name from error code
    private getErrorName = (codeEnum: any, errorCode: number): string => {
        const keys = Object.keys(codeEnum).filter((x) => codeEnum[x] == errorCode);

        return keys.length > 0
            ? keys[0] as string
            : '';
    };

    private isStreamingRequestType = (requestType: net.NetworkingEngine.RequestType): boolean => {
        return (
            requestType === net.NetworkingEngine.RequestType.MANIFEST
            || requestType === net.NetworkingEngine.RequestType.SEGMENT
        );
    };

    private getNetworkErrorData = (errorCode: number, errorData: unknown): ShakaNetworkError | null => {
        if (errorCode !== util.Error.Code.BAD_HTTP_STATUS || !Array.isArray(errorData)) {
            return null;
        }

        const httpCode = errorData[1] as number;
        const requestType = errorData[4] as number;

        const isStreamingError = this.isStreamingRequestType(requestType);

        return { httpCode, isStreamingError };
    };

    protected dispatchManifestTypeChangedEvent(): void {
        this.dispatchEvent(new ExternalPlayerManifestTypeChangedEvent());
    }

    private dispatchErrorEvent(error: util.Error) {
        const errorCode = error.code as number;
        const errorData = error.data as unknown;

        const errorName = this.getErrorName(util.Error.Code, errorCode);
        const networkErrorData = this.getNetworkErrorData(errorCode, errorData);

        this.dispatchEvent(new ExternalPlayerErrorEvent(
            errorCode,
            errorData,
            errorName,
            networkErrorData?.httpCode ?? null,
            networkErrorData?.isStreamingError ?? false,
        ));
    }

    private dispatchRecoveryErrorEvent() {
        this.dispatchEvent(new ExternalPlayerRecoveryErrorEvent());
    }

    private readonly onShakaBuffering = (): void => {
        this.dispatchEvent(new ExternalPlayerBufferingEvent());
    };

    private readonly onShakaVariantChanged = (): void => {
        this.dispatchEvent(new ExternalPlayerVariantChangedEvent());
    };

    private onShakaTrackChanged = (): void => {
        this.dispatchEvent(new ExternalPlayerTrackChangedEvent());
    };

    private readonly onShakaTextChanged = (): void => {
        this.dispatchEvent(new ExternalPlayerTextChangedEvent());
    };

    private readonly onShakaExpirationUpdated = (): void => {
        if (!this.licenseRenewalManager) {
            return;
        }

        this.logger.debug('Shaka player license expiration update event');

        const expiration = this.getLicenseExpiration();

        if (!expiration) {
            this.logger.debug('License expiration is null. Probably DRM free content.');

            return;
        }

        this.logger.debug('License expiration ', new Date(expiration));

        this.licenseRenewalManager.scheduleLicenseRenewal({
            expiration,
            onLicenseRenewalNeeded: () => {
                this.logger.debug('License renewal is needed');

                this.dispatchEvent(new ExternalLicenseRenewalNeeded());
            },
        });


    };

    public get isBuffering(): boolean {
        return this.shakaPlayer.isBuffering();
    }

    public get isPaused(): boolean {
        return this.shakaPlayer.getMediaElement()?.paused ?? false;
    }

    public isBrowserSupported = true;

    public getTextTracks(hardOfHearingSubtitleLanguages: string[] = []): ExternalPlayerTrack[] {
        return this.shakaPlayer
            .getTextTracks()
            .filter(({ language }) => !this.subtitlesManifestTracks?.adBreaksOnlyTracksMap[language])
            .filter(({ language, label }) => language || label)
            .map((track) => {
                const { language, roles, active, id, label } = track;
                const isHardOfHearing = this.isHardOfHearing(track);

                return ({
                    id: String(id),
                    language: language || label!,
                    role: roles[0] || '',
                    isActive: active,
                    isHardOfHearing: Boolean(isHardOfHearing) || hardOfHearingSubtitleLanguages.some(lang => lang === language)
                });
            }
            );
    }

    public getAdBreaks(): PlayerAdBreak[] {
        return this.playerAdBreaks;
    }

    public getScteSegments(): PlayerScteSegment[] {
        return this.playerScteSegments;
    }

    public getCurrentFrameRate(): number | null {
        const activeTrack = this.getActiveTrack();

        return activeTrack?.frameRate ?? null;
    }

    public getAudioTracks(audioDescriptionLanguages: string[] = []): ExternalPlayerTrack[] {
        const variants = this.shakaPlayer.getVariantTracks();
        const activeVariant = this.getActiveTrack();
        const audioAndRoles = this.shakaPlayer.getAudioLanguagesAndRoles().filter((trackAudio) => {
            if (trackAudio.role === 'main' || trackAudio.role === 'description') {
                return true;
            }

            return variants.find((variant) => variant.language == trackAudio.language &&
                variant.audioRoles?.includes((trackAudio.role)) && !variant.audioRoles?.includes(('description')));
        });
        const usedIds: number[] = [];

        return audioAndRoles
            .filter(({ language }) => !this.audioManifestTracks?.adBreaksOnlyTracksMap[language])
            .map(({ language, role }, index) => {
                const variant = variants.find((track) => this.checkVariant(track, language, role, usedIds));
                const variantAudioId = variant?.audioId;

                variantAudioId && usedIds.push(variantAudioId);
                const id = variant?.id || index;

                const isLanguageSame = activeVariant!.language == language;
                const isLabelSame = activeVariant!.label == language;
                const hasSameRole = activeVariant!.roles.includes(role);
                const isActive = (isLanguageSame || isLabelSame) && hasSameRole;

                const isAudioDescription = variant && this.isAudioDescription(variant);


                return ({
                    id: String(id),
                    language,
                    role,
                    isActive,
                    isAudioDescription: Boolean(isAudioDescription) || audioDescriptionLanguages.some(lang => lang === language)
                });
            });
    }

    public getStats(): ExternalPlayerStatistics {
        return this.shakaPlayer.getStats() as ExternalPlayerStatistics;
    }

    public getDiagnosticInfo(): string {
        const activeTrack = this.getActiveTrack();
        const stats = this.shakaPlayer.getStats();

        const diagnosticInfo = {
            drm: this.selectedDrm?.name,
            bitrate: stats.streamBandwidth,
            decodedFrames: stats.decodedFrames,
            droppedFrames: stats.droppedFrames,
            corruptedFrames: stats.corruptedFrames,
            bufferingTime: stats.bufferingTime,
            switchHistoryLength: stats.switchHistory.length,
            bufferFullness: this.shakaPlayer.getBufferFullness(),
            video: {
                mime: activeTrack?.mimeType,
                id: activeTrack?.videoId,
                resolution: `${activeTrack?.width ?? 0}x${activeTrack?.height ?? 0}`,
                aspect: activeTrack?.pixelAspectRatio,
                bitrate: activeTrack?.videoBandwidth,
                framerate: activeTrack?.frameRate,
                language: activeTrack?.language,
                codec: activeTrack?.videoCodec,
            },
            audio: {
                mime: activeTrack?.mimeType,
                id: activeTrack?.audioId,
                sampleRate: activeTrack?.audioSamplingRate,
                channels: activeTrack?.channelsCount,
                bitrate: activeTrack?.audioBandwidth,
                language: activeTrack?.language,
                codec: activeTrack?.audioCodec,
            }
        };

        return JSON.stringify(diagnosticInfo, null, 2);
    }

    public getBufferedInfo(): ExternalPlayerBufferedInfo {
        const currentTime = this.shakaPlayer.getMediaElement()?.currentTime ?? 0;
        const { total } = this.shakaPlayer.getBufferedInfo();
        const { end = 0 } = last(total) ?? {};
        const bufferLengthInSec = end - currentTime;

        return {
            bufferLength: bufferLengthInSec * SECOND,
        };
    }

    public getLicenseExpiration(): number | null {
        return this.shakaPlayer.getExpiration();
    }

    public async load(url: string, manifest: string, mediaPlaylist?: string, startTime?: number): Promise<void> {
        await this.ensureConfiguredBasedOnSupport();

        if (!this.isBrowserSupported) {
            throw new Error();
        }

        this.configureCustomDisplayer();

        this.hasVideoBeenStarted() && await this.unload();

        this.url = url;

        await this.preloadCertificate();

        this.configurePersistenceLicense();

        this.configureLicenseRenewal();

        this.logger.debug('load::configuration', this.shakaPlayer.getConfiguration());

        this.configureManifestParser();

        await this.preload(url, manifest, mediaPlaylist);

        await this._load(url, startTime);
    }

    private getActiveTrack(): extern.Track | undefined {
        const variants = this.shakaPlayer.getVariantTracks();

        return variants.find((track) => track.active) || variants[0];
    }

    private async _load(url: string, startTime?: number) {
        try {
            await this.shakaPlayer.load(url, startTime, this.mimeType);

            this.dispatchEvent(new ExternalPlayerLoaded());
        } catch (error: unknown) {
            const receivedError = error as util.Error;
            const isLoadInterrupted = receivedError.code === util.Error.Code.LOAD_INTERRUPTED;

            !isLoadInterrupted && this.dispatchErrorEvent(receivedError);
        }
    }

    private async ensureConfiguredBasedOnSupport() {
        if (this.selectedDrm) {
            return;
        }

        let probeSupport: SupportType;

        if (this.forceKeySystem) {
            probeSupport = this.getForcedDrmProbeSupport(this.forceKeySystem);
        } else {
            probeSupport = await this.getProbeSupport();
        }

        this.configurePlayerBasedOnSupport(probeSupport);
    }

    public selectAudioLanguage(id: string, language: string, role?: string): void {
        const audioTracks = this.getAudioTracks();

        this.logger.log('Current audio track list: ', audioTracks);

        if (this.isTrackActive(audioTracks, id, language)) {
            this.logger.log(`audio track with language: ${language} is already active.`, role);

            return;
        }

        this.shakaPlayer.configure({
            preferredAudioLanguage: language,
            preferredVariantRole: role,
        });

        this.logger.log(`selecting audio track to language: ${language}`);
        this.shakaPlayer.selectAudioLanguage(language, role);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected disableTextTrack(_currentTextTracks?: TextTrackList): void {
        this.shakaPlayer.setTextTrackVisibility(false);
    }

    public selectTextLanguage(id: string, language: string, role?: string, currentTextTracks?: TextTrackList): void {
        if (!id || !language) {
            this.shakaPlayer.configure({
                preferredTextLanguage: '',
                preferredTextRole: '',
            });

            this.logger.log('disabling text tracks.');
            this.disableTextTrack(currentTextTracks);
            this.onShakaTextChanged();

            return;
        }

        const textTracks = this.getTextTracks();

        this.logger.log('Current text track list: ', textTracks);

        if (this.isTrackActive(textTracks, id, language)) {
            this.logger.log(`text track with language: ${language} is already active.`, role);
        } else {
            this.shakaPlayer.configure({
                preferredTextLanguage: language,
                preferredTextRole: role,
            });

            this.logger.log(`selecting text track to language: ${language}`);
            this.shakaPlayer.selectTextLanguage(language, role);
        }

        this.shakaPlayer.setTextTrackVisibility(true);
    }

    private isTrackActive(tracks: ExternalPlayerTrack[], id: string, language: string) {
        const activeTrack = tracks.find((track) => track.isActive);

        return activeTrack && activeTrack.id === id && activeTrack.language === language;
    }

    public clear(): void {
        this.segmentsRecoveryService.clear();
        this.audioManifestTracks = null;
        this.subtitlesManifestTracks = null;

        if (this.licenseRenewalManager) {
            this.logger.debug('Stop license renewal manager');

            this.licenseRenewalManager.stop();
            this.licenseRenewalManager = null;
        }
    }

    public unload(initializeMediaSource?: boolean): Promise<void> {
        this.logger.debug('unload:: has been called');

        if (this.unloadPromise) {
            return this.unloadPromise;
        }

        this.unloadPromise = this.shakaPlayer.unload(initializeMediaSource).finally(() => {
            this.logger.debug('unload:: has been finished');
            this.unloadPromise = null;
        });

        return this.unloadPromise;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setStyles(_isVod: boolean, _subtitleBackgroundVOD: string | undefined, _subtitleFontVOD: string | undefined, _isFontLoaded: boolean, _logger: ILogger): void {
        //empty
    }

    public setAdBreakType(adBreakType: AdBreaksParsingType): void {
        this.adBreakType = adBreakType;
    }

    public setShouldParsePreRoll(shouldParsePreRoll: boolean): void {
        this.shouldParsePreRoll = shouldParsePreRoll;
    }

    public setPersistentLicenseOptions(opt: PersistentLicenseOptions): void {
        this.logger.debug('setPersistentLicenseOptions::', opt);
        this.persistentLicenseOptions = opt;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setIgnoredScteTypes(_ignoredSegmentationTypeIds?: number[]): void {
        // empty
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setDrmConfiguration(_drmConfiguration: DrmConfiguration): void {
        // empty
    }

    public isAudioDescriptionStandartWayOfSignalingAvailable(): boolean {
        return this.audioDescriptionStandartWayOfSignaling.length !== 0;
    }

    public isHardOfHearingStandartWayOfSignalingAvailable(): boolean {
        return this.hardOfHearingStandartWayOfSignaling.length !== 0;
    }

    private setResolutionRestrictions(maxWidth?: number, maxHeight?: number): void {
        this.shakaPlayer.configure({
            abr: { restrictions: { maxWidth, maxHeight } }
        });
    }

    private setSoftFrameRateRestrictions(maxFrameRate: number): void {
        this.shakaPlayer.configure({
            abr: { restrictions: { maxFrameRate } }
        });
    }

    private setHardFrameRateRestrictions(maxFrameRate: number): void {
        this.shakaPlayer.configure({
            restrictions: { maxFrameRate }
        });
    }

    public setRestrictions(restrictions: RestrictionsConfiguration): void {
        const { softFrameRate, hardFrameRate, maxResolutionWidth, maxResolutionHeight } = restrictions;

        this.setResolutionRestrictions(
            maxResolutionWidth || this.defaultSoftRestrictions.maxWidth,
            maxResolutionHeight || this.defaultSoftRestrictions.maxHeight
        );
        this.setSoftFrameRateRestrictions(softFrameRate || this.defaultSoftRestrictions.maxFrameRate);
        this.setHardFrameRateRestrictions(hardFrameRate || this.defaultHardRestrictions.maxFrameRate);
    }

    public setAudioPreferences(audioLang: string | undefined, audioRole: string | undefined): void {
        if (audioLang) {
            this.logger.log(`Setting preferred audio language to ${audioLang}`);

            this.shakaPlayer.configure({ preferredAudioLanguage: audioLang });
        }

        if (audioRole === ROLE_VALUES.alternate) {
            this.logger.log(`Setting preferred audio role to ${audioRole}`);

            this.shakaPlayer.configure({ preferredVariantRole: audioRole });
        }
    }

    public setDrmContentId(drmContentId: string): void {
        this.drmContentId = drmContentId;
    }

    public setStartOverUpdateInterval(interval: number): void {
        this.startOverUpdateInterval = interval;
    }

    private manifestResponseHandler(response: extern.Response) {
        const data = response?.data;
        const manifest = fromUtf8(data);

        const manifestConfig = {
            minimumUpdatePeriod: this.startOverUpdateInterval,
        };

        this.processManifest(response, manifest, manifestConfig);
        this.parseManifestAndEmit(manifest);
        this.setResponseHeader(JSON.stringify(response?.headers));
    }

    public setResponseHeader(headers: string) {
        this.manifestResponseHeader = headers;
    }

    public get manifestResponseheader(): string {
        return this.manifestResponseHeader;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected processManifest(_response: extern.Response, _manifest: string, _config: ManifestConfig): void {
        //empty
    }

    protected parseManifestAndEmit(manifest: string): void {
        this.parseManifest(manifest);

        this.dispatchEvent(new ExternalPlayerManifestParsedEvent());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected parseManifest(_manifest: string): void {
        //empty
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected isAudioDescription(_track: extern.Track): boolean {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected isHardOfHearing(_track: extern.Track): boolean {
        return false;
    }

    public get seekRange(): extern.BufferedRange {
        return this.shakaPlayer.seekRange();
    }

    public get reviewBuffer(): number {
        const seekRange = this.seekRange;

        return seekRange.end - seekRange.start;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected checkVariant(_track: extern.Track, _language: string, _role: string, _usedIds?: number[]): boolean {
        return false;
    }

    protected async request(requestUrl: string): Promise<ExternalPlayerResponse> {
        const response = await fetch(requestUrl);

        const { ok, headers, status } = response;
        const data = await response.text().catch();

        const responseData = { data, headers, status };

        if (!ok) {
            throw responseData;
        }

        return responseData;
    }

    public get shouldHandleStartPosition(): boolean {
        return false;
    }

    public setPlaybackStartState(): void {
        //empty
    }

    public get duration(): number {
        const duration = this.shakaPlayer.getMediaElement()?.duration;

        if (duration === INFINITE_DURATION || duration === Infinity) {
            return this.getSeekableDuration() ?? 0;
        }

        return duration ?? 0;
    }

    protected getSeekableDuration(): number | undefined {
        return;
    }

    public get manifestType(): string {
        return this.getManifestType() ?? '';
    }

    protected getManifestType(): string | null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected isPersistentStateRequiredSupported(_keySystem: ProtectionSystemType): Promise<boolean> {
        return Promise.resolve(false);
    }

    protected getForcedDrmProbeSupport(keySystem: KeySystem): SupportType {
        const protectionScheme: ProtectionSystemType = KeySystemToProtectionSystemType[keySystem];

        const result = {} as SupportType;

        result[protectionScheme] = {
            persistentState: false,
            persistentStateRequired: keySystem === KeySystem.Playready || this.targetOs !== OS.webOS,
        };

        return result;
    }

    protected async getProbeSupport(): Promise<SupportType> {
        const testKeySystems = this.drmPriority;

        const support = this.drmPriority.reduce((acc, keySystem) => {
            acc[keySystem] = null;

            return acc;
        }, {} as SupportType);

        // Try the offline config first, then fall back to the basic config.
        const configs = [
            offlineConfig as MediaKeySystemConfiguration,
            basicConfig as MediaKeySystemConfiguration,
        ];

        const tests = testKeySystems.map(async (keySystem) => {
            support[keySystem] = await this.testKeySystem(keySystem, configs);
        });

        await Promise.all(tests);

        return support;
    }

    protected async testKeySystem(keySystem: ProtectionSystemType, configs: MediaKeySystemConfiguration[]): Promise<DrmSupportType | null> {
        try {
            const access = await navigator.requestMediaKeySystemAccess(keySystem, configs);

            const { sessionTypes } = access.getConfiguration();

            const persistentStateSessionType = sessionTypes ?
                sessionTypes.includes('persistent-license') : false;

            const persistentStateRequired = await this.isPersistentStateRequiredSupported(keySystem);

            const support = {
                persistentState: persistentStateSessionType,
                persistentStateRequired: persistentStateRequired,
            };

            await access.createMediaKeys();

            return support;
        } catch (e) {
            return null;
        }
    }

    /**
     * Returns true, if video playback has been started,
     * some part of the video has already been played (seen) by the user
     *
     */
    public hasVideoBeenStarted(): boolean {
        return !!this.shakaPlayer.getMediaElement()?.played.length;
    }

    /**
     * Detaches current media element from the player
     * and attaches a provide one instead
     *
     */
    public async useMediaElement(mediaElement: HTMLMediaElement): Promise<void> {
        await this.shakaPlayer.detach();
        await this.shakaPlayer.attach(mediaElement);
    }

    /**
     * Configures custom text displayer
     *
     */
    private configureCustomDisplayer(): void {
        const mediaElement = this.shakaPlayer.getMediaElement();

        if (mediaElement) {
            const videoContainer = this.videoContainer;

            const customDisplayFactory = function textDisplayFactory() {
                const textDisplayer = new UITextDisplayer(mediaElement, videoContainer);

                return textDisplayer;
            };

            this.shakaPlayer.configure('textDisplayFactory', customDisplayFactory);
        } else {
            this.logger.error('Media element must be attached to ShakaPlayer to configure custom text displayer.');
        }
    }

    public shouldWaitForCanPlayEventAfterSeek(): boolean {
        return false;
    }
}
